import React from "react";
import styled from "styled-components";
import hr from "../images/hr.svg";

const StyledHr = styled.hr`
  background: transparent url('${hr}') no-repeat center center;
  min-height: 6rem;
  margin: 10rem 0 10rem;

  @media (max-width: 768px) {
    margin: 6rem 0 7rem;
  }

  @media (max-width: 550px) {
    margin: 5rem 0 6rem;
  }
`;

export const Hr = () => <StyledHr />;
