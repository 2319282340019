import styled from "styled-components";

export const H1 = styled.h1`
  font-size: 7.2rem;
  font-weight: 700;
  letter-spacing: -0.2rem;

  @media (max-width: 768px) {
    font-size: 4.8rem;
  }

  @media (max-width: 550px) {
    font-size: 5rem;
  }
`;

export const H2 = styled.h2`
  font-size: 6rem;
  font-weight: 700;
  letter-spacing: -0.2rem;
  margin-bottom: 7.8rem;

  @media (max-width: 768px) {
    font-size: 5rem;
    margin-bottom: 4.8rem;
  }

  @media (max-width: 550px) {
    font-size: 4rem;
    margin-bottom: 4.8rem;
  }
`;
