import React from 'react';
// import {Link} from "gatsby";
import styled from 'styled-components';

import Me from '../images/me.svg';
import Location from '../images/location.svg';
import Pen from '../images/pen.svg';
import Shopify from '../images/shopify.svg';

import {H1, H2} from 'components/Headings';
import {Hr} from 'components/Hr';
// import Image from "../components/Image";
import Layout from '../components/Layout';
import SEO from '../components/Seo';

const Introduction = styled.div`
  display: flex;
  max-width: 70rem;
  margin: 0 auto;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    max-width: 55rem;
  }

  @media (max-width: 550px) {
    flex-direction: column;
    text-align: center;
  }

  > div {
    padding-left: 10.5rem;

    @media (max-width: 768px) {
      padding-left: 5rem;
    }

    @media (max-width: 550px) {
      padding-left: 0;
    }
  }

  h1 {
    position: relative;
    display: inline-block;

    &:before {
      z-index: -1;
      content: '';
      position: absolute;
      bottom: 0;
      width: 105%;
      height: 1.6rem;
      background: #eee;

      @media (max-width: 550px) {
        transform: translateX(-50%);
        left: 50%;
        width: 110%;
      }
    }
  }

  p {
    font-size: 2.4rem;
    line-height: 1.5;
    font-weight: 500;

    @media (max-width: 768px) {
      font-size: 2rem;
    }
  }

  img {
    max-width: 13.5rem;
  }
`;

const KeyFacts = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  justify-content: center;

  > div {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;

    img {
      max-height: 9rem;
    }

    p {
      display: inline-block;
      font-weight: 500;
      max-width: 30rem;
      line-height: 1.3;
      margin-left: 2rem;
    }
  }
`;

const Blog = styled.section`
  h2 {
    display: inline-block;
    position: relative;

    &:before {
      z-index: -1;
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 110%;
      height: 1.6rem;
      background: repeating-linear-gradient(
        45deg,
        transparent,
        transparent 30%,
        #eee 30%,
        #eee 70%
      );
      background-size: 7.7% 100%;
      transform: translateX(-50%);
    }
  }

  ul {
    margin: 0;
    padding: 0;
    max-width: 79.5rem;
    margin: 0 auto;
  }
`;

const BlogPost = styled.li`
  list-style: none;
  margin-bottom: 3.4rem;

  h3 {
    font-size: 2.4rem;
    line-height: 1.2;
    margin-bottom: 0.8rem;

    a {
      color: #000;
      text-decoration-color: #000;
    }
  }

  p {
    font-weight: 500;
    line-height: 1.3;
  }
`;

const Projects = styled.section`
  h2 {
    text-align: center;
  }
`;

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Introduction>
      <img src={Me} alt="me" />
      <div>
        <H1>Hi, I’m Neele!</H1>
        <p>
          I am a Software Engineer with focus on web&nbsp;development &amp;
          design.
        </p>
      </div>
    </Introduction>
    <Hr />
    <KeyFacts>
      <div>
        <img src={Location} alt="" />
        <p>I&nbsp;currently&nbsp;live&nbsp;in Salzburg,&nbsp;Austria.</p>
      </div>
      <div>
        <img src={Shopify} alt="" />
        <p>I work at Shopify and make commerce better for&nbsp;everyone.</p>
      </div>
      <div>
        <img src={Pen} alt="" />
        <p>
          I also write on Medium about&nbsp;things I have been working&nbsp;on.
        </p>
      </div>
    </KeyFacts>
    <Hr />
    <Blog>
      <H2>Things I write about</H2>
      <ul>
        <BlogPost>
          <h3>
            <a href="https://levelup.gitconnected.com/successfully-porting-complex-data-driven-frontends-to-react-w-graphql-83a011da5a2a">
              Successfully porting complex data-driven frontends to React
              with GraphQL
            </a>
          </h3>
          <p>
            When porting existing code to React, there is a couple of things to
            look out for. This article shares the experience I have made while…
          </p>
        </BlogPost>
        <BlogPost>
          <h3>
            <a href="https://medium.com/swlh/why-graphql-connection-types-dont-work-well-with-apollo-cache-c1858a897e12">
              Why GraphQL Connection types don’t work well with Apollo cache
            </a>
          </h3>
          <p>
            You love Apollo cache? So do we! 3 things to watch out for when
            using GraphQL Connections with Apollo cache.
          </p>
        </BlogPost>
      </ul>
    </Blog>
    <Hr />
    {/* <Projects>
      <H2>My side hustles</H2>
    </Projects> */}
    {/* <Image /> */}
    {/* <Link to="/page-2">Go to page 2</Link> */}
  </Layout>
);

export default IndexPage;
